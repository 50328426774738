$brown: #C85B00;
$blue: #5A8CDA;
$yellow: #DEB931;
$orange: #D48700;
$strange: #D1816D;
$burgundy: #B2004F;
$green: #6CA765;
$polygon: [[0, 25], [90, 15], [80, 100], [3, 95]];
$colors: ($green, $burgundy, $blue, $yellow, $strange, $orange);

@function random-polygon() {
    $top-left: [5 + random(10), 5 + random(10)];
    $top-right: [95 - random(10), 5 + random(10)];
    $bottom-right: [95 - random(10), 95 - random(10)];
    $bottom-left: [5 + random(10), 95 - random(10)];

    @return [$top-left, $top-right, $bottom-right, $bottom-left];
}

@function sum($numbers...) {
    $sum: 0;

    @each $number in $numbers {
        $sum: $sum + $number;
    }

    @return $sum;
}

@function percentage($el, $total) {
    $result: $el * 100 / $total; 
    @return $result;
}

@function parse-polygon($list) {
    $result: null;

    @for $i from 1 through length($list) {
        $n: nth($list, $i);
        $result: $result unquote("#{nth($n, 1)}% #{nth($n, 2)}%");

        @if $i != length($list) {
            $result: unquote("#{$result},")
        }
    }

    @return $result;
}

@function update-shape($list) {
    $new: ();

    @each $sub in $list {
        $new: append($new, [nth($sub, 1) - 5 + random(10), nth($sub, 2) - 5 + random(10)])
    }

    @return parse-polygon($new);
}

@mixin polygon-anim($list) {
    $animation-number: 0 !default !global;
    $animation-number: $animation-number + 1 !global;
    $name: polygon-show-#{$animation-number};

    animation: $name 30s linear infinite both;

    @at-root {
        @keyframes #{$name} {
            0% {
                clip-path: polygon(parse-polygon($list));
            }

            @for $i from 1 through 9 {
                #{$i}0% {
                    clip-path: polygon(update-shape($list));
                }
            }

            100% {
                clip-path: polygon(parse-polygon($list));
            }
        }
    }
}

@mixin pseudo-base {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
}

@mixin laptop {
    @media screen and (max-width: 1600px) {
        @content;
    }
}

.dust-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 1000;

    span[class^=dust-] {
        background: #fff;
        opacity: .5;
    }

    $type: 1;

    @for $size from 1 through 20 {
        $start: floor($size / 5) + 1;

        @for $blur from $start to $size {
            @if $size > 1 and $blur > 1 {
                .dust-#{$type} {
                    width: #{$size}px;
                    height: #{$size}px;
                    filter: blur(#{$blur}px);

                    $type: $type + 1;
                }
            }
        }
    }

    $positions: [];

    @for $i from 1 through 100 {
        $positions: append($positions, [random(100), random(100)]);
    }

    $directions: [];

    @for $i from 1 through 200 {
        $X: random(50) - 25;
        $Y: random(50) - 25;
        $directions: append($directions, [$X, $Y]);
    }

    $animations: [];

    @for $i from 1 through 100 {
        $directs: [];

        @for $n from 1 through 4 {
            $directs: append($directs, nth($directions, random(200)));
        }

        $durations: [];
        $current-position: [0, 0];

        @each $n in $directs {
            $X-diff: nth($current-position, 1) - nth($n, 1);
            $Y-diff: nth($current-position, 2) - nth($n, 2);
            $durations: append($durations, sum(abs($X-diff), abs($Y-diff)));
        }

        $total-duration: 0;

        @each $n in $durations {
            $total-duration: $total-duration + $n;
        }

        @keyframes animation-#{$i}-kf {
            $current-percent: 0;

            0% {
                transform: translate(0, 0);
            }

            @for $n from 1 through 4 {
                $current-percent: $current-percent + percentage(nth($durations, $n), $total-duration);

                #{$current-percent}% {
                    transform: translate(#{nth(nth($directs, $n), 1))}rem, #{nth(nth($directs, $n), 2)}rem);
                }
            }

            100% {
                transform: translate(0, 0);
            }
        }

        .animation-#{$i} {
            animation: animation-#{$i}-kf #{$total-duration}s infinite linear both;
        }
    }

    @for $i from 1 through 150 {
        $position: nth($positions, random(100));

        .dust-#{$i} {
            position: absolute;
            top: #{nth($position, 1)}vh;
            left: #{nth($position, 2)}vw;
        }
    }
}
