@import 'mixins';

nav {
    position: fixed;
    top: 0;
    left: 10rem;
    width: 16.4rem;
    z-index: 100;

    .logo {
        position: absolute;
        left: -4rem;
        width: 20rem;
        z-index: 1;

        img {
            width: 100%;
        }
    }

    .container {
        height: 0;
        margin-top: 3rem;
        overflow: hidden;
        transition: .6s;
    
        ul {
            padding: 5rem 2.5rem 1rem;
            background: rgba(0, 0, 0, .7);
            border-radius: 0 0 .3rem 1rem;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - .8rem));
            transform: translateY(-100%);
            transition: .6s;

            li {
                display: flex;
                align-items: center;
                margin: 1.2rem 0;

                img {
                    width: 1.8rem;
                }

                a {
                    font-size: 1.05rem;
                    margin-left: 1rem;
                    transition: .3s;

                    &.inactive {
                        cursor: not-allowed;
                    }
                }

                &:hover a {
                    transform: translateX(.5rem);
                }
            }
        }
    }

    .burger {
        position: absolute;
        top: 2.5rem;
        right: -.7rem;
        width: 2.5rem;
        height: 2.5rem;
        background: $blue;
        border-radius: 50%;
        cursor: pointer;
        transition: .3s;
        z-index: 1;

        &:hover {
            transform: scale(1.1);
        }

        span {
            @include pseudo-base;

            display: block;
            width: 50%;
            height: 7.5%;
            background: #fff;
            border-radius: 1rem;
            transform: rotate(0);
            transition: top .3s .3s, bottom .3s .3s, transform .3s;

            &:first-child {
                top: -1rem;
            }

            &:nth-child(2) {
                bottom: -1rem;
            }
        }

        &:after {
            @include pseudo-base;

            content: 'menu';
            left: calc(100% + .5rem);
            right: auto;
            display: flex;
            align-items: center;
            font-weight: 400;
            transition: content 0s .3s, transform .6s;
            transform: rotateX(0);
        }
    }

    &.active {
        .container {
            height: 100%;

            ul {
                transform: translateY(0);
            }
        }

        .burger {
            &:after {
                content: 'close';
                transform: rotateX(360deg);
            }

            span {
                transition: top .3s, bottom .3s, transform .3s .3s;

                &:first-child {
                    top: 0;
                    transform: rotate(45deg);
                }

                &:nth-child(2) {
                    bottom: 0;
                    transform: rotate(-45deg);
                }


                &:nth-child(3) {
                    height: 0;
                }
            }
        }
    }
}
