@import '../base';

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Light'), 
         local('Gilroy-Light'),
         url('../../vendor/fonts/Gilroylight.woff2') format('woff2'), 
         url('../../vendor/fonts/Gilroylight.woff') format('woff'), 
         url('../../vendor/fonts/Gilroylight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Regular'), 
         local('Gilroy-Regular'),
         url('../../vendor/fonts/Gilroyregular.woff2') format('woff2'), 
         url('../../vendor/fonts/Gilroyregular.woff') format('woff'), 
         url('../../vendor/fonts/Gilroyregular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Medium'),
         local('Gilroy-Medium'),
         url('../../vendor/fonts/Gilroymedium.woff2') format('woff2'),
         url('../../vendor/fonts/Gilroymedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Bold'),
         local('Gilroy-Bold'),
         url('../../vendor/fonts/Gilroybold.woff2') format('woff2'),
         url('../../vendor/fonts/Gilroybold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy ExtraBold'), 
         local('Gilroy-ExtraBold'), 
         url('../../vendor/fonts/Gilroyextrabold.woff2') format('woff2'), 
         url('../../vendor/fonts/Gilroyextrabold.woff') format('woff'), 
         url('../../vendor/fonts/Gilroyextrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Heavy'),
         local('Gilroy-Heavy'),
         url('../../vendor/fonts/Gilroyheavy.woff2') format('woff2'),
         url('../../vendor/fonts/Gilroyheavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

.stub {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000;

    img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 8rem;
        margin: auto;
    }

    @media (orientation: landscape) {
        display: none;
    }
}

html {
    font-size: 12px;
}

nav {
    left: 0;
    width: 18rem;

    a {

        .logo {
            position: relative;
            left: 0;
            width: 100%;
        }
    }

    .container {
        margin-top: -4rem;

        ul {
            padding: 3rem 2rem .5rem;

            li {
                margin: .9rem 0;

                a {
                    font-size: 1.2rem;
                }
            }

            li:last-child {
                justify-content: flex-end;

                a img {
                    width: 4rem;
                }
            }
        }
    }

    .lang-buttons {
        position: static;
        justify-content: center;

        a {
            font-size: 1.5rem !important;
        }
    }

    .burger {
        top: 2rem;
        width: 3rem;
        height: 3rem;

        &:after {
            font-size: .8rem;
        }
    }
}

.kan, .valprim , .phone{
    height: 4rem;

    img {
        width: auto;
    }
}

.kan {
    top: 1.5rem;
    right: 4rem;
}

.valprim {
    top: 1.5rem;
    right: .5rem;
}

@keyframes scroll-arrow {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(100%);
    }
}

.scroll {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    width: .3rem;
    height: 3rem;
    margin: auto;

    &:after {
        @include pseudo-base;

        content: 'scroll';
        left: -3rem;
        display: flex;
        align-items: center;
    }
    
    span {
        @include pseudo-base;

        overflow: hidden;
        outline: none;

        &:after {
            @include pseudo-base;

            background: #fff;
            transform: translateY(-100%);
            animation: scroll-arrow ease-in-out 1.5s infinite;
        }
    }
}

#bingc-phone-button {
    display: block !important;
    left: 2% !important;
    bottom: -1.5% !important;
    transform: scale(.55) !important;
}

#bingc-active {
    display: none !important;
}
