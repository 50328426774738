@import 'nav';
@import 'mixins';

$font-formats: ('woff2': 'woff2', 'woff': 'woff', 'ttf': 'truetype');

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Light'), 
         local('Gilroy-Light'),
         url('../vendor/fonts/Gilroylight.woff2') format('woff2'), 
         url('../vendor/fonts/Gilroylight.woff') format('woff'), 
         url('../vendor/fonts/Gilroylight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Regular'), 
         local('Gilroy-Regular'),
         url('../vendor/fonts/Gilroyregular.woff2') format('woff2'), 
         url('../vendor/fonts/Gilroyregular.woff') format('woff'), 
         url('../vendor/fonts/Gilroyregular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Medium'),
         local('Gilroy-Medium'),
         url('../vendor/fonts/Gilroymedium.woff2') format('woff2'),
         url('../vendor/fonts/Gilroymedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Bold'),
         local('Gilroy-Bold'),
         url('../vendor/fonts/Gilroybold.woff2') format('woff2'),
         url('../vendor/fonts/Gilroybold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy ExtraBold'), 
         local('Gilroy-ExtraBold'), 
         url('../vendor/fonts/Gilroyextrabold.woff2') format('woff2'), 
         url('../vendor/fonts/Gilroyextrabold.woff') format('woff'), 
         url('../vendor/fonts/Gilroyextrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Heavy'),
         local('Gilroy-Heavy'),
         url('../vendor/fonts/Gilroyheavy.woff2') format('woff2'),
         url('../vendor/fonts/Gilroyheavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

$screen-sizes: (2560: 18, 1920: 16, 1600: 14, 1400: 12, 768: 12, 1366: 10);

@each $size, $font in $screen-sizes {
    @media screen and (max-width: #{$size}px) {
        html {
            font-size: #{$font}px;
        }
    }
}

@media screen and (max-width: 768px) {
    .stub-mob {
        display: block;
    }
}

body {
    margin: 0;
    font-family: Gilroy;
    line-height: initial;
    font-weight: normal;
    color: #fff;
    outline: none;
}

h1, h2, h3, h4 {
    margin: 0;
    font-family: Gilroy;
    font-weight: 800;
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2.5rem;
}

h3 {
    font-size: 2rem;
}

h4 {
    font-size: 1.5rem;
}

figure {
    margin: 0;
}

p {
    margin: 0;
    font-family: Gilroy;
    font-weight: 500;
}

a {
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    font-family: Gilroy;
    font-weight: 500;

    &:focus,
    &:active {
        outline: none;
    }
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sprite {
    display: none;
}

.social-icons {
    position: fixed;
    left: 3rem;
    bottom: 1rem;
    display: flex;
    align-items: center;
    z-index: 20;

    a {
        margin: 0 .5rem;

        svg {
            width: 2.5rem;

            .round {
                fill: transparent;
                stroke: #fff;
                transition: .3s;
            }

            .icon {
                fill: #fff;
                stroke: transparent;
            }
        }

        &.sound {
            display: flex;
            align-items: center;

            svg {
                position: relative;

                .icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    opacity: 0;
                    visibility: hidden;
                    transition: .3s;

                    &.active {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            span {
                margin-top: .3rem;
                margin-left: .5rem;
                font-size: .7rem;
            }
        }

        &:last-child {
            margin-left: 2rem;
        }

        &:hover svg {
            .round {
                fill: $yellow;
                stroke: $yellow;
            }
        }

        img {
            width: 5rem;
            transition: .3s;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

.kan, 
.valprim {
    position: fixed;
    top: 2rem;
    right: 3rem;
    height: 5rem;
    z-index: 20;

    img {
        height: 100%;
        transition: .3s;

        &:hover {
            transform: scale(1.1);
        }
    }
}

.kan {
    right: 9rem;
}

.lang-buttons {
    position: fixed;
    top: 4rem;
    right: 15rem;
    display: flex;
    z-index: 20;
    
    a {
        margin-right: 1rem;
        font-size: 1.2rem;
        color: rgba(255, 255, 255, .6);
        transition: .3s;

        &:hover {
            transform: scale(1.1);
            color: #fff;
        }

        &.active {
            color: #fff;
        }
    }
}

.copyright {
    position: fixed;
    right: 0;
    bottom: 1rem;
    left: 0;
    text-align: center;
    font-size: .8rem;
    z-index: 1;
}

.stub {
    display: none;
}

.base-layer {
    width: 100vw;
    height: 100vh;
    padding: 10px;
    box-sizing: border-box;
    transform-style: preserve-3d;
    pointer-events: none;

    .layer,
    .head {
        position: absolute;
        top: 0;
        left: 0;
        margin: auto;
        transform-style: preserve-3d;
        backface-visibility: hidden;
    }
}

#bingc-phone-button {
    display: block !important;
    right: 0% !important;
    left: auto !important;
    bottom: 6% !important;
    transform: scale(.7) !important;
}

#bingc-active {
    display: none !important;
}

@media screen and (max-width: 768px) {
    .stub {            
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        background: #000;
        z-index: 10000;

        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            max-width: 60%;
            max-height: 60%;
        }
    }
}
