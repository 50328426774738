@import 'base';
@import '../../vendor/css/fullpage.min.css';

.fade-img{
    display: block;
    max-height: 90%;
}
.fade-img-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .7);
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    cursor: pointer;
    z-index: -1;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.shown {
        display: flex;
        z-index: 1001;
        opacity: 1;
        visibility: visible;
    }
}
.fade-banner {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .7);
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    cursor: pointer;
    z-index: 1000;

    &.active {
        opacity: 1;
        visibility: visible;
        transition: 0s;
    }

    & > img,
    .banners {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 70%;
        margin: auto;
    }

    .banners {
        display: grid;
        align-content: space-evenly;

        img {
            width: 14rem;
            margin: auto;
        }
    }

}

.background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

.text {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 10vh;
    max-width: 80%;
    margin: auto;

    h1 {
        position: relative;
        display: block;
        font-size: 4rem;
        margin-bottom: 1rem;
        text-align: center;
        text-shadow: 4px 4px 4px rgba(0, 0, 0, .3);
        z-index: 0;

        .section:last-child & {
            font-size: 3.5rem;
        }

        .en .section:nth-child(3) & {
            font-size: 3rem;
        }

        &:after {
            $polygon: [[3.5, 14.5], [96.1, 4.75], [89, 100], [6, 90.5]];
            $polygon_2: [[15, 17], [89, 0], [85, 96], [17, 95]];
            $polygon_3: [[15, 17], [89, 0], [85, 96], [17, 95]];
            $polygon_4: [[12.5, 14.5], [86.5, 28.9], [90.4, 86.25], [15, 90.5]];
            $polygon_5: [[24.63, 14.5], [78.72, 30.03], [82.51, 86.82], [22.25, 94.35]];
            $polygon_6: [[10.5, 8.62], [90.6, 25], [93.1, 90.2], [5.88, 98.34]];

            $polygon_3_en: [[10, 17], [96.88, 2], [92.25, 96], [12, 95]];
            $polygon_4_en: [[30.5, 14.5], [67.6, 28.9], [74.1, 86.25], [33, 90.5]];
            $polygon_5_en: [[34.63, 14.5], [69.8, 30.03], [73.1, 86.82], [32.25, 94.35]];

            $polygons-list: ($polygon, $polygon_2, $polygon_3, $polygon_4, $polygon_5, $polygon_6);
            $polygons-list-en: ($polygon_3_en, $polygon_4_en, $polygon_5_en);
            $colors: ($green, $burgundy, $blue, $yellow, $strange, $orange);

            @include pseudo-base;

            top: -1rem;
            bottom: -.5rem;
            left: -2rem;
            background: $blue;
            z-index: -1;

            @for $i from 1 through 6 {
                .section:nth-child(#{$i}) & {
                    @include polygon-anim(nth($polygons-list, $i));

                    background: nth($colors, $i);
                    clip-path: polygon(parse-polygon(nth($polygons-list, $i)));
                }
            }

            @for $i from 1 through 3 {
                .en .section:nth-child(#{$i + 2}) & {
                    @include polygon-anim(nth($polygons-list-en, $i));

                    clip-path: polygon(parse-polygon(nth($polygons-list-en, $i)));
                }
            }
        }
    }

    p {
        font-size: 1.1rem;
        text-shadow: 3px 3px 3px rgba(0, 0, 0, .5);
    }

    a {
        position: absolute;
        right: 0;
        bottom: -3rem;
        display: table;
        font-size: 1rem;
        transition: .3s;

        &:hover {
            color: $yellow;
        }
    }
}

.actions {
    position: fixed;
    top: 35%;
    right: -.5rem;
    font-size: 1.5rem;
    transform: rotate(-90deg);
    cursor: pointer;

    &:after {
        @include pseudo-base;

        top: 3.8rem;
        left: -.7rem;
        width: 7rem;
        height: 9rem;
        background: red;
        transform: rotate(120deg);
        transition: .3s;
        z-index: -1;
    }

    .en & {
        font-size: 1.2rem;

        &:after {
            height: 10rem;
            left: 0;
        }
    }

    &.active:after {
        border: 2px solid #fff;
    }
}

.actions-list {
    position: fixed;
    top: 20vh;
    right: 20vw;;
    bottom: 30vh;
    left: 10vw;
    display: grid;
    margin: auto;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    opacity: 0;
    visibility: hidden;
    transition: .5s;

    &.active {
        opacity: 1;
        visibility: visible;
    }

    a {
        img {
            width: 100%;
        }
    }
}

.viber {
    position: fixed;
    left: 7.5rem;
    bottom: 1rem;
    z-index: 15;

    img {
        display: block;
        width: 2.5rem;
        margin: 1rem auto;
        transition: .3s;
    }
}
